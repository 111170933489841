<template>
  <header-component />

  <about-component />
  <process-component />

  <case-studies-component />
  <FAQsComponent />
  <!-- <team-component /> -->
  <contact-component />
  <footer-component />
</template>

<script>
import HeaderComponent from "@/components/HeaderComponent.vue";
//import TeamComponent from "@/components/TeamComponent.vue";
import AboutComponent from "@/components/AboutComponent.vue";
import ContactComponent from "@/components/ContactComponent.vue";
import ProcessComponent from "@/components/ProcessComponent.vue";
import CaseStudiesComponent from "@/components/CaseStudiesComponent.vue";
import FAQsComponent from "@/components/FAQsComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";

export default {
  name: "App",
  components: {
    HeaderComponent,
    //TeamComponent,
    AboutComponent,
    FAQsComponent,
    CaseStudiesComponent,
    ContactComponent,
    ProcessComponent,
    FooterComponent,
  },
};
</script>

<style>
body {
  height: 100%;
  height: 100vh;
}
</style>
