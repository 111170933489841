<template>
  <div id="faq" class="container">
    <hr />
    <div class="p-5">
      <h1>FAQ</h1>
      <div class="accordion accordion-flush" id="faqs">
        <FAQComponent
          v-for="(faq, index) of faqs"
          :key="faq"
          :question="faq.question"
          :answer="faq.answer"
          :id="index"
        />
      </div>
    </div>
  </div>
</template>

<script>
import FAQComponent from "./FAQComponent.vue";
import json from "@/assets/faqs.json";
export default {
  components: { FAQComponent },
  data() {
    return {
      faqs: json,
    };
  },
};
</script>

<style scoped>
.container {
  scroll-margin-top: 70px;
  padding-bottom: 40px;
}
</style>
