<template>
  <div class="container">
    <h2 class="img-fluid py-2">{{ product.name }}</h2>

    <ul class="text list-unstyled">
      <li v-for="copy in product.copy" :key="copy">{{ copy }}</li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    product: Object,
  },
};
</script>

<style scoped>
img {
  max-width: 100%;
  margin: 20px;
}
</style>
