import { createApp } from "vue";
import App from "./App.vue";
import HomeView from "@/views/HomeView";
import IlisoView from "@/views/IlisoView";
import * as vueRouter from "vue-router";

import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const routes = [
  { path: "/", component: HomeView },
  { path: "/iliso", component: IlisoView },
];

const router = vueRouter.createRouter({
  history: vueRouter.createWebHistory(),
  routes,
});

router.beforeEach((to, from) => {
  if (from.path === "/iliso" && to.hash !== "") {
    window.location = `/${to.hash}`;
  }
});

createApp(App).use(router).mount("#app");
