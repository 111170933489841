<template>
  <div id="about" class="container-fluid py-5">
    <div class="h-100 d-inline-block">
      <div class="row p-3">
        <div class="col-4 img-col d-none d-md-block">
          <img class="img-fluid" src="@/assets/simplifi_logo_shrt.png" />
        </div>
        <div class="col-md-8 col-12 p-3 text-start">
          <h1 class="py-3">{{ title }}</h1>
          <h4 class="paragraph">{{ paragraph1 }}</h4>
          <h4 class="paragraph">{{ paragraph2 }}</h4>

          <ul>
            <li v-for="bullet in bullets" :key="bullet">
              <h4>{{ bullet }}</h4>
            </li>
          </ul>

          <h4 class="paragraph">{{ paragraph4 }}</h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutComponent",
  data() {
    return {
      title: "Your partner in product development",
      paragraph1:
        "At Simplifi, we are your dedicated partners in the realm of product development, offering development services across hardware, firmware, and full-stack software. We are your one stop shop for product development in the landscape of electronics and the Internet of Things (IoT).",
      paragraph2: "We offer the following services.",
      bullets: [
        "Project Feasibility and Proof of Concept Development",
        "Software, Hardware, and Electronic Prototyping",
        "Product Design for Manufacturing",
        "Product Small Batch Manufacturing",
        "Continued Support and Maintenance",
      ],
      paragraph4:
        "Let us help you navigate the complexities of your development journey, turning your ideas into tangible success.",
    };
  },
};
</script>

<style scoped>
.container-fluid {
  padding: 50px;
  margin: auto;
  scroll-margin-top: 80px;
}

.img-col {
  max-width: 450px;
  margin: auto;
  padding: 20px;
}

.info {
  padding: 10px;
  max-width: 900px;
}

h1 {
  padding-bottom: 20px;
}

h5 {
  color: black;
}

.paragraph {
  padding-bottom: 20px;
}
</style>
