<template>
  <div id="iliso" class="container-fluid text-light text-center">
    <h1 class="p-2">How it works</h1>
    <div class="row p-3">
      <div v-for="(box, index) in boxes" :key="box" class="col-md col-12">
        <img
          class="img-fluid py-3"
          :src="require(`@/assets/iliso_process_${index + 1}.png`)"
        />
        <h5>{{ box.title }}</h5>
        <p>{{ box.text }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      boxes: [
        {
          title: "Power",
          text: "Iliso is powered by a wall socket but has a backup battery to ensure monitoring continues when there is a power failure. ",
        },
        {
          title: "Connectivity",
          text: "Any phone can be used to give your wi-fi name and password to the device, it will then connect automatically and start sending sensor values.",
        },
        {
          title: "Sensing",
          text: "Data from the sensors is collected every 10 seconds and published every 5 minutes if no alert is triggered. ",
        },
        {
          title: "Alerting",
          text: "If an alert is triggered a light and buzzer on the device will be activated for on sight notification, and a push notification will be sent via WhatsApp or Email.",
        },
        {
          title: "Reporting",
          text: "Live and historical data views are available on the dashboard. There is also an option to download a CSV file with the data for further use.",
        },
      ],
    };
  },
};
</script>

<style scoped>
.container-fluid {
  scroll-margin-top: 70px;
  padding: 70px;
  background-color: #2a3760;
}

.container-fluid > * {
  margin: auto;
}

.img-fluid {
  max-width: 150px;
}
</style>
