<template>
  <div id="process" class="container-fluid text-light text-center">
    <h1 class="p-2">Our Process</h1>
    <div class="row p-3">
      <div v-for="(box, index) in boxes" :key="box" class="col-md col-12">
        <img
          class="img-fluid py-3"
          :src="require(`@/assets/simplifi_process_${index + 1}.png`)"
        />
        <h5>{{ box.title }}</h5>
        <p>{{ box.text }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      boxes: [
        {
          title: "Ideation",
          text: "We will assist in ideation, making the design process easier, adding functionality and saving overall costs.",
        },
        {
          title: "POC",
          text: "Ensuring your idea will work while saving you time and money. A proof of concept is a crucial step in product development.",
        },
        {
          title: "Prototype",
          text: "A prototype confirms the viability of the technology and provides feedback in the design process.",
        },
        {
          title: "Testing",
          text: "To ensure that your final product works with no issue, testing is crucial to the development process.",
        },
        {
          title: "V1 Product",
          text: "The V1 product is ready and tested for reliability and proper functionality.",
        },
      ],
    };
  },
};
</script>

<style scoped>
.container-fluid {
  scroll-margin-top: 70px;
  padding: 70px;
  background-color: #2a3760;
}

.container-fluid > * {
  margin: auto;
}

.img-fluid {
  max-width: 150px;
}
</style>
