<template>
  <div class="container">
    <h2>Send us a message</h2>

    <form class="text-start mt-4" v-if="!submitted">
      <h6>
        Tell us more about your project and how Simplifi Engineering could help
        you
      </h6>
      <div class="mb-3">
        <label for="fullName" class="form-label">Full Name</label>
        <input
          class="form-control"
          id="fullName"
          aria-describedby="fullName"
          v-model="fullName"
          required
        />
      </div>
      <div class="mb-3">
        <label for="fullName" class="form-label">Email Address</label>
        <input
          class="form-control"
          id="email"
          type="email"
          v-model="email"
          aria-describedby="email"
          required
        />
      </div>
      <div class="mb-3">
        <label for="company" class="form-label">Company Name</label>
        <input
          class="form-control"
          id="company"
          v-model="company"
          aria-describedby="company"
          required
        />
      </div>
      <div class="mb-3">
        <label for="contactNumber" class="form-label">Contact Number</label>
        <input
          class="form-control"
          id="contactNumber"
          type="tel"
          v-model="contactNumber"
          aria-describedby="contactNumber"
          required
        />
      </div>
      <div class="mb-3">
        <label for="exampleFormControlTextarea1" class="form-label">
          Project Description
          <ul>
            <li>What problem are you solving and what is your solution?</li>
            <li>What problem can Simplifi help you with?</li>
            <li>At what stage is your project currently?</li>
          </ul>
        </label>
        <textarea
          class="form-control"
          id="exampleFormControlTextarea1"
          rows="3"
          v-model="description"
          required
        ></textarea>
      </div>
      <button
        @click="submit"
        :disabled="formIsDisabled()"
        class="btn btn-primary"
      >
        Submit
      </button>
    </form>
    <div v-else>
      <h6>Thank you for contacting us. Someone will be in touch shortly</h6>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      fullName: "",
      email: "",
      company: "",
      contactNumber: "",
      description: "",
      submitted: false,
    };
  },

  methods: {
    async submit(event) {
      event.preventDefault();
      this.addConversationTag();
      await this.createGoogleForm();
      this.submitted = true;
    },

    async createGoogleForm() {
      const data = new FormData();

      data.append("entry.950981894", this.fullName);
      data.append("entry.103713779", this.email);
      data.append("entry.239922633", this.company);
      data.append("entry.1864677195", this.contactNumber);
      data.append("entry.1315958210", this.description);

      try {
        await axios({
          method: "post",
          url: "https://docs.google.com/forms/u/0/d/e/1FAIpQLSeoMVJ2nKOXTHJzrp5PyL1AimLmTRLwf1LrRM0Etdp9S_S6tg/formResponse",
          data: data,
          withCredentials: false,
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        });
      } catch (error) {
        console.log(error);
      }
    },

    addConversationTag() {
      const st = document.createElement("script");
      st.innerHTML =
        "gtag('event', 'conversion', {'send_to': 'AW-16633471087/F9VXCKjttNAZEO_Auvs9'});";
      document.head.appendChild(st);
    },

    validateEmail(email) {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailPattern.test(email);
    },

    formIsDisabled() {
      if (
        this.fullName &&
        this.email &&
        this.validateEmail(this.email) &&
        this.company &&
        this.contactNumber &&
        this.description
      ) {
        return false;
      } else return true;
    },
  },
};
</script>

<style></style>
