<template>
  <div id="iliso" class="container-fluid p-5">
    <h1 class="p-5">Pricing</h1>
    <div class="row">
      <div v-for="product in products" :key="product" class="col-12 col-md-6">
        <product-component :product="product" />
      </div>
    </div>
  </div>
</template>

<script>
import ProductComponent from "./ProductComponent.vue";
export default {
  name: "PricingComponent",
  components: { ProductComponent },

  data() {
    return {
      products: [
        {
          name: "Device",
          copy: [
            "Sensor Hub - R2500",
            "Temperture Sensor - R200",
            "Humidity Sensor - R250",
          ],
        },
        {
          name: "Web App",
          copy: ["Subscription - R100 per month per device", "", ""],
        },
      ],
    };
  },
};
</script>

<style scoped>
.container-fluid {
  scroll-margin-top: 80px;
  padding: 70px;
  padding-bottom: 50px;
  background-color: #fefbea;
}
</style>
