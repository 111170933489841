<template>
  <div class="accordion-item">
    <h2 class="accordion-header" :id="`faq-heading-${id}`">
      <button
        class="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        :data-bs-target="`#faq-collapse-${id}`"
        aria-expanded="true"
        aria-controls="collapseOne"
      >
        {{ question }}
      </button>
    </h2>
    <div
      :id="`faq-collapse-${id}`"
      class="accordion-collapse collapse"
      data-bs-parent="#faqs"
    >
      <div class="accordion-body">
        <p class="text-start">{{ answer }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: Number,
    question: String,
    answer: String,
  },
};
</script>

<style scoped></style>
