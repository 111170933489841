<template>
  <nav id="navbar" class="navbar navbar-dark fixed-top navbar-expand-lg">
    <div class="container-fluid">
      <a class="navbar-brand" href="./">
        <img src="@/assets/simplifi_logo.png" alt="" />
      </a>

      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon text-light"></span>
      </button>

      <div class="collapse navbar-collapse w-100" id="navbarNav">
        <ul class="navbar-nav ms-auto w-100 justify-content-end">
          <li class="nav-item">
            <a class="nav-link fs-5" href="#about">About</a>
          </li>

          <li class="nav-item">
            <a class="nav-link fs-5" href="#process">Process</a>
          </li>

          <li class="nav-item">
            <a class="nav-link fs-5" href="#case-studies-section"
              >Case Studies</a
            >
          </li>

          <li class="nav-item">
            <a class="nav-link fs-5" href="#faq">FAQ</a>
          </li>

          <!--  <li class="nav-item">
            <a class="nav-link fs-5" href="#team">Team</a>
          </li> -->

          <li class="nav-item">
            <a class="nav-link fs-5" href="#contact">Contact</a>
          </li>

          <!-- <li class="nav-item">
            <a class="nav-link fs-5" href="/iliso">Iliso</a>
          </li> -->
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {};
</script>

<style scoped>
.navbar {
  padding: 20px;
  background-color: #2a3760;
  border-bottom: 2px solid rgb(63, 63, 63);
}

.container-fluid {
  margin: 0px;
}

.nav-link {
  margin-left: 10px;
  margin-right: 10px;
}

img {
  max-width: 170px;
}
</style>
