<template>
  <router-view></router-view>
</template>

<script>
import { initializeApp } from "firebase/app";
import * as Analytics from "firebase/analytics";

export default {
  name: "App",
  components: {},

  setup() {
    const firebaseConfig = {
      apiKey: "AIzaSyBScjRxuxzRxxsTkZbLp7qhDuCU2hK1M88",
      authDomain: "simplifi-solutions-4a865.firebaseapp.com",
      projectId: "simplifi-solutions-4a865",
      storageBucket: "simplifi-solutions-4a865.appspot.com",
      messagingSenderId: "714464181501",
      appId: "1:714464181501:web:38fde1b45d4d5507b4d294",
      measurementId: "G-RXG8B4QP4F",
    };

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const analytics = Analytics.getAnalytics(app);

    Analytics.logEvent(analytics, "site_visit");
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

body {
  padding-top: 100px;
}
</style>
