<template>
  <div class="container-fluid">
    <p class="text-center text-light p-2">
      2024 Simplifi Consultancy. All Rights Reserved.
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.container-fluid {
  background-color: #2a3760;
  padding: 0px;
  margin: 0px;
}
</style>
