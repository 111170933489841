<template>
  <div class="container" id="case-studies-section">
    <div class="p-5">
      <h1>Case Studies</h1>
      <div class="accordion accordion-flush" id="case-studies">
        <case-study-component
          v-for="(study, index) of caseStudies"
          :key="study"
          :title="study.title"
          :overview="study.overview"
          :development="study.development"
          :testimonials="study.testimonials"
          :id="index"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CaseStudyComponent from "./CaseStudyComponent.vue";
import json from "@/assets/case-studies.json";
export default {
  components: { CaseStudyComponent },
  data() {
    return {
      caseStudies: json,
    };
  },
};
</script>

<style scoped>
.container {
  padding-top: 40px;
  scroll-margin-top: 70px;
}
</style>
