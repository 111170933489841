<template>
  <header-component />
  <about-component />
  <process-component />
  <pricing-component />
  <contact-component />
</template>

<script>
import HeaderComponent from "@/components/HeaderComponent.vue";
import PricingComponent from "@/components/PricingComponent.vue";
import AboutComponent from "@/components/IlisoAboutComponent.vue";
import ContactComponent from "@/components/ContactComponent.vue";
import ProcessComponent from "@/components/IlisoProcessComponent.vue";

export default {
  name: "App",
  components: {
    HeaderComponent,
    AboutComponent,
    ContactComponent,
    ProcessComponent,
    PricingComponent,
  },

  setup() {},
};
</script>
