<template>
  <div id="iliso" class="container-fluid py-5">
    <div class="h-100 d-inline-block">
      <div class="row p-3">
        <div class="col-4 img-col d-none d-md-block">
          <img class="img-fluid" src="@/assets/iliso_box.png" />
        </div>
        <div class="col-md-8 col-12 p-3 text-start">
          <h1 class="py-3">{{ title }}</h1>
          <h4 class="paragraph">{{ paragraph1 }}</h4>
          <h4 class="paragraph">{{ paragraph2 }}</h4>

          <ul>
            <li v-for="bullet in bullets" :key="bullet">
              <h4>{{ bullet }}</h4>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutComponent",
  data() {
    return {
      title: "Remote monitoring for everyone",
      paragraph1:
        "A wireless hub that can accomodate 4 temperature or humidity sensors. Iliso allows you to remotely monitor the temperature or humidity of anything. It uses a web application to display the sensor data and will send you appropriate alerts when the sensor data is at an undesireable value. ",
      paragraph2:
        "An easy setup and installation process allows you to access the following features from a remote dashboard.",
      bullets: [
        "Audio and visual alert notifications",
        "SMS alert notifications",
        "Live and historic data values",
      ],
    };
  },
};
</script>

<style scoped>
.container-fluid {
  padding: 50px;
  margin: auto;
  scroll-margin-top: 80px;
}

.img-col {
  max-width: 450px;
  margin: auto;
  padding: 20px;
}

.info {
  padding: 10px;
  max-width: 900px;
}

h1 {
  padding-bottom: 20px;
}

h5 {
  color: black;
}

.paragraph {
  padding-bottom: 20px;
}
</style>
