<template>
  <div class="accordion-item">
    <h2 class="accordion-header" :id="`heading-${id}`">
      <button
        class="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        :data-bs-target="`#collapse-${id}`"
        aria-expanded="true"
        aria-controls="collapseOne"
      >
        {{ title }}
      </button>
    </h2>
    <div
      :id="`collapse-${id}`"
      class="accordion-collapse collapse"
      data-bs-parent="#case-studies"
    >
      <div class="accordion-body">
        <h5>Overview</h5>
        <p>{{ overview }}</p>
        <h5>Development</h5>
        <p>{{ development }}</p>

        <div v-if="testimonials">
          <h5>Testimonial</h5>
          <p v-for="testimonial in testimonials" :key="testimonial">
            {{ testimonial }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: Number,
    title: String,
    overview: String,
    development: String,
    testimonials: Array,
  },
};
</script>

<style scoped></style>
