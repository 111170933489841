<template>
  <div id="contact" class="container-fluid p-5 text-light">
    <h1 class="text-center">Contact Us</h1>
    <div class="row">
      <div class="col-12 col-md-6 py-2">
        <div class="d-flex py-2 flex text-start">
          <div class="row">
            <p>082 728 5689</p>
            <p>info@simplifiengineering.co.za</p>
          </div>
        </div>
        <iframe
          class="d-sm-none d-md-block pt-2"
          width="100%"
          height="600"
          style="border: 0"
          loading="lazy"
          allowfullscreen
          src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJFfBpB--zzR0RGDkUcNErHCQ&key=AIzaSyC9BsaE_QEwpszHuKEhnsBwiB-VhCIMk6g"
        ></iframe>
      </div>
      <div class="col-12 col-md-6 py-2">
        <contact-form />
      </div>
      <div class="col-12 col-md-6 pt-2 d-sm-block d-md-none">
        <iframe
          width="100%"
          height="600"
          style="border: 0"
          onLoad="console.log('hello')"
          loading="lazy"
          allowfullscreen
          src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJFfBpB--zzR0RGDkUcNErHCQ&key=AIzaSyC9BsaE_QEwpszHuKEhnsBwiB-VhCIMk6g"
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import ContactForm from "./ContactForm.vue";
export default {
  components: { ContactForm },
  setup() {},
};
</script>

<style scoped>
.container-fluid {
  padding-top: 20%;
  padding-bottom: 20%;
  background-color: #2a3760;

  scroll-margin-top: 80px;
}

img {
  max-width: 70px;
  padding: 10px;
}

.row {
  padding: 5px;
}
</style>
